import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <section id="Contact">
      <h2>South Summit Fire Protection District</h2>
      <p>
        Thank you for taking to time to submit your comments, suggestions and
        requests for assistance. South Summit Fire Protection District staff
        will answer your comments and suggestions quickly and efficiently.
        <br />
        <br />
        If you want to contact us directly, call us at 435-783-2375. If you have
        an emergency, please call 911.
        <br />
        <br />
        <br />
        <b>Fire Marshall</b> <br />
        Jackson Coleman <br />
        Phone: (435)962-2884 <br />
        Email:{" "}
        <a href="mailto:jcoleman@southsummitfd.gov">
          jcoleman@southsummitfd.gov
        </a>
      </p>
      {/* Add more content about your department */}
    </section>
  );
}

export default Contact;
